import React, { useEffect, useState } from "react";
import AlternativeHeader from "components/Headers/AlternativeHeader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
} from "reactstrap";
import DataTable from "react-data-table-component";
import {
  getEmergencyContact,
  updateUserStatus,
  exportEmergencyContact,
} from "services/services";
import { useHistory } from "react-router";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import { CSVLink, CSVDownload } from "react-csv";
import { getSubscriptionList } from "services/services";
import moment from "moment";

const Subscription = () => {
  const [perPage, setPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [subscriptionList, setSubscriptionList] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const [exportsubscriptionList, setExportSubscriptionList] = useState("");

  const history = useHistory();

  const statusApi = () => {
    return selectStatus === "true" ? true : selectStatus === "true" ? false : ""
  }

  useEffect(() => {
    let params = {
      limit: perPage,
      page_no: pageNo,
      keyword: searchInput,
      status: statusApi(),
    };

    getSubscriptionApiCall(params);

    let dataSubsExport = { status: statusApi(), keyword: searchInput, limit: totalRows, page_no: 1 };
    exportSubscription(dataSubsExport);
  }, []);

  const onEdit = (userId) => {
    history.push({
      pathname: "/admin/subscription-details/" + userId,
    });
  };

  let count = 1;

  const columns = [
    {
      name: "S. No.",
      selector:  (row, index) => (pageNo - 1) * perPage + index + 1,
      sortable: true,
    },
    {
      name: "User Name",
      selector: (row) => row?.user?.userName,
      sortable: true,
    },
    {
      name: "Email Address",
      minWidth: "250px",
      selector: (row) => row?.user?.email,
      sortable: true,
      compact: true,
    },
    {
      name: "Plan Duration",
      selector: (row) => row?.subscriptionPlanId == "ResQ_Monthly_Subscriptions" ? "Monthly" : "Yearly",
    },
    // {
    //   name: "Purchased on",
    //   selector: (row) => moment(row?.createdAt).format("M/DD/YYYY"),
    //   sortable: true, 
    // },
    {
      name: "Purchased on",
      selector: (row) => new Date(row?.createdAt), // Return Date object instead of formatted string
      sortable: true,
      sortFunction: (a, b) => new Date(a.createdAt) - new Date(b.createdAt), // Custom sort function
      cell: (row) => moment(row?.createdAt).format("M/DD/YYYY"), // Keep the formatted display
    },
    {
      name: "Subscribe Through",
      selector: (row) => row?.type ? row?.type === "In-app purchase" ? row?.deviceType == 'ios' ? "Apple" : "Google" : row?.type : "N/A",
    },
    {
      name: "Status",
      selector: (row) => row?.isActive,
      cell: (row) => {
        return (
          <div>
            {(new Date() < new Date(row?.endDate)) ? (
              <span
                class="badge badge-success"
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontSize: "11px",
                  padding: "9px",
                }}
              >
                Active 
              </span>
            ) : (
              <span
                class="badge badge-danger"
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "11px",
                  padding: "9px",
                }}
              >
                Inactive
              </span>
            )}
          </div>
        );
      },
    },
    {
      center: true,
      name: "Action",
      maxWidth: "120px",
      cell: (row) => {
        return (
          <div className="d-flex justify-content-center">
            <>
              <Button
                className="mr-2 btn-primary"
                onClick={() => onEdit(row.userId)}
              >
                <i className="fa fa-eye" />
              </Button>
            </>
          </div>
        );
      },
    },
  ];

  const getSubscriptionApiCall = async (params) => {
    setLoading(true);
    console.log("let see change", searchInput);
    try {
      let response = await getSubscriptionList(params);
      console.log("search ", response);
      if (response && response.status === 200) {
        setSubscriptionList(response?.data?.data);
        setTotalRows(response.data?.totalResults);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setSubscriptionList();
    }
  };

  const onSearch = async (e) => {
    console.log("here change ", e.target.value);
    setSearchInput(e.target.value);
    let dataSubsExport = { status: statusApi(), keyword: e.target.value, limit: totalRows, page_no: 1 };
    exportSubscription(dataSubsExport);
    if (e.target.value.length > 2) {
      let params = {
        limit: perPage,
        page_no: pageNo,
        keyword: e.target.value,
        status: statusApi(),
      };
      getSubscriptionApiCall(params);
    } else {
      let params = {
        limit: perPage,
        page_no: pageNo,
        keyword: e.target.value,
        status: statusApi(),
      };
      getSubscriptionApiCall(params);
    }
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPageNo(page);
    let params = {
      limit: perPage,
      page_no: page,
      keyword: searchInput,
      status: statusApi(),
    };
    getSubscriptionApiCall(params);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    let params = {
      limit: newPerPage,
      page_no: page,
      keyword: searchInput,
      status: statusApi(),
    };
    getSubscriptionApiCall(params);
    setPerPage(newPerPage);
  };

  const handleSelect = (e) => {
    setLoading(true);
    console.log("here slect", e.target.value);
    let params = {
      limit: perPage,
      page_no: pageNo,
      keyword: searchInput,
      status: e.target.value,
    };
    getSubscriptionApiCall(params);
    setSelectStatus(e.target.value);
    let dataSubsExport = { limit: perPage,
      page_no: 1,
      keyword: searchInput,
      status: e.target.value,
      limit: totalRows
    };
    exportSubscription(dataSubsExport);
  };
  // {
  //   name: "Subscribe Through",
  //   selector: (row) => row?.type ? row?.type === "In-app purchase" ? row?.deviceType == 'ios' ? "Apple" : "Google" : row?.type : "N/A",
  // },
  const convertExport = (convData) => {
    let arr = []
    if (convData.length > 0) {
      convData.map((item) => {
        arr.push({
          userName: item?.user?.userName,
          email: item?.user?.email,
          planDuration: item?.subscriptionPlanId === "ResQ_Monthly_Subscriptions" ? "Monthly" : "Yearly",
          purchaseOn: moment(item?.createdAt).format("M/DD/YYYY"),
          subscribeThrough: item?.type ? item?.type === "In-app purchase" ? item?.deviceType == 'ios' ? "Apple" : "Google" : item?.type : "N/A",
          status: item?.isActive ? "Active" : "Inactive"
        })
      })
    }
    return arr
  }
  
  const exportSubscription = async (dataSubsExport) => {
    try {
      let response = await getSubscriptionList(dataSubsExport);
      if (response && response.status === 200) {
        let coData = convertExport(response.data.data)
        setExportSubscriptionList(coData);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <AlternativeHeader pageTitle="Subscription" parentComponent="" />

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Subscription</h3>
              </CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col md="3">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Search"
                        name="searchInput"
                        type="text"
                        onChange={onSearch}
                        autoComplete="off"
                        value={searchInput || ""}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="9">
                    <div className="d-flex justify-content-end align-items-center flex-wrap">
                      <Row className="mb-1">
                        <Col md="6">
                          <Input
                            type="select"
                            name="selectStatus"
                            onChange={handleSelect}
                            id="exampleSelect"
                          >
                            <option value="">All</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </Input>
                        </Col>
                        <Col md="6">
                          <CSVLink
                            data={exportsubscriptionList}
                            filename={"SubscriptionDetails.csv"}
                            className="btn btn-info"
                          >
                            Export
                          </CSVLink>

             
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <DataTable
                  columns={columns}
                  data={subscriptionList}
                  progressPending={loading}
                  progressComponent={
                    <Spinner
                      as="span"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                  striped
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Subscription;
